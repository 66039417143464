import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import schildkroete from '../../images/projects/illustrationen/schildkroete.jpg';
import elephant from '../../images/projects/illustrationen/elephant-ganz.jpg';
import igel from '../../images/projects/illustrationen/igel.jpg';
import cover from '../../images/projects/illustrationen/01_cover.jpg';
import cover2x from '../../images/projects/illustrationen/01_cover@2x.jpg';
import img1 from '../../images/projects/illustrationen/img1.jpg';
import img12x from '../../images/projects/illustrationen/img1@2x.jpg';
import img2 from '../../images/projects/illustrationen/img2.jpg';
import img22x from '../../images/projects/illustrationen/img2@2x.jpg';
import img3 from '../../images/projects/illustrationen/img3.jpg';
import img32x from '../../images/projects/illustrationen/img3@2x.jpg';

const title = 'Illustrationen zweier Projekte';
const text =
	'Das freie Zeichnen zum Thema „Fantastische Tierwelten“ zeigt verschiedene Bilder, die jeweils ihren ganz eigenen, spannenden Kosmos repräsentieren, in dem es viel zu entdecken gibt. Denn individuelle Zeichenstile und selbstgewählte Farben erzählen ganz andere Geschichten als Fotos. Ziel des Projektes war es, die Stadt Trier in Form von Illustrationen zu veranschaulichen.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<Image src={schildkroete} />
				</ProjectItem>
				<ProjectItem>
					<Image src={elephant} />
				</ProjectItem>
				<ProjectItem>
					<Image src={igel} />
				</ProjectItem>
				<ProjectItem>
					<Image src={cover} src2={cover2x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img1} src2={img12x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img2} src2={img22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={img3} src2={img32x} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
